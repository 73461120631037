import React from "react";
import { connect } from "react-redux";
import { Link } from "gatsby";

import { lingua_it } from "../i18n/gdpr-it-IT";
import { lingua_en } from "../i18n/gdpr-en-GB";
import { lingua_es } from "../i18n/gdpr-es-ES";
import { lingua_fr } from "../i18n/gdpr-fr-FR";

import turrisi1 from "../images/gift-cards/giftcard50.png";
import turrisi2 from "../images/gift-cards/giftcard100.png";
import turrisi3 from "../images/gift-cards/giftcard150.png";

const GiftCards = (props) => {
  let lingua = {};

  switch (props.lang) {
    case "it-IT":
      lingua = lingua_it;
      break;
    case "fr-FR":
      lingua = lingua_fr;
      break;
    case "es-ES":
      lingua = lingua_es;
      break;
    case "en-GB":
      lingua = lingua_en;
      break;
    default:
      lingua = lingua_it;
  }

  return (
    <div className="section">
      <div className="container">
        <div className="content">
          <h1 className="title is-spaced is-3">Gift Cards</h1>
          <section className="section ">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <div class="card">
                    <div className="card-image">
                      <figure>
                        <img src={turrisi1} />
                      </figure>
                    </div>
                    <div className="card-content">
                      <div className="media">
                        <div class="media-content">
                          <p class="title is-4">Gift Card 60€</p>
                        </div>
                      </div>

                      <div class="content">
                      Per ogni occasione speciale,  una Gift Card Turrisi è un regalo sempre apprezzato. 
                        <br />
                      </div>
                      <footer class="card-footer">
                        <p class="card-footer-item">
                          <span>
                            <a
                              className="button is-success is-large"
                              href="https://giftup.app/place-order/118401a1-34e1-4378-af2e-83b776a6274d?platform=hosted&productId=4d786fd7-b8c6-447a-dc90-08d9a43624cc&language=it-IT"
                            >
                              Acquista
                            </a>
                          </span>
                        </p>
                      </footer>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div class="card">
                    <div className="card-image">
                      <figure>
                        <img src={turrisi2} />
                      </figure>
                    </div>
                    <div className="card-content">
                      <div className="media">
                        <div class="media-content">
                          <p class="title is-4">Gift Card 100€</p>
                        </div>
                      </div>

                      <div class="content">
                      Per ogni occasione speciale,  una Gift Card Turrisi è un regalo sempre apprezzato. 
                        <br />
                      </div>
                      <footer class="card-footer">
                        <p class="card-footer-item">
                          <span>
                            <a
                              className="button is-success is-large"
                              href="https://giftup.app/place-order/118401a1-34e1-4378-af2e-83b776a6274d?platform=hosted&productId=420dde30-fa33-4390-dc91-08d9a43624cc&language=it-IT"
                            >
                              Acquista
                            </a>
                          </span>
                        </p>
                      </footer>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div class="card">
                    <div className="card-image">
                      <figure>
                        <img src={turrisi3} />
                      </figure>
                    </div>
                    <div className="card-content">
                      <div className="media">
                        <div class="media-content">
                          <p class="title is-4">Gift Card 150€</p>
                        </div>
                      </div>

                      <div class="content">
                      Per ogni occasione speciale,  una Gift Card Turrisi è un regalo sempre apprezzato. 
                        <br />
                      </div>
                      <footer class="card-footer">
                        <p class="card-footer-item">
                          <span>
                            <a
                              className="button is-success is-large"
                              href="https://giftup.app/place-order/118401a1-34e1-4378-af2e-83b776a6274d?platform=hosted&productId=5745e516-6c82-4f05-dc93-08d9a43624cc&language=it-IT"
                            >
                              Acquista
                            </a>
                          </span>
                        </p>
                      </footer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    lang: state.shop.lingua,
  };
}

export default connect(mapStateToProps)(GiftCards);
